import { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '@/components/widgets/Spinner/';
import AgentLayout from '@/components/layouts/AgentLayout';


// 设备管理
const AgentCommunityListPage = lazy(() => import(/* webpackChunkName: "pages/community/communities" */ '@/components/pages/AgentCommunityListPage/'));
const AgentInboxListPage = lazy(() => import(/* webpackChunkName: "pages/community/inboxes" */ '@/components/pages/AgentInboxListPage/'));
const AgentUserListPage = lazy(() => import(/* webpackChunkName: "pages/community/users" */ '@/components/pages/AgentUserListPage/'));

// 其它
const NotFoundPage = lazy(() => import(/* webpackChunkName: "pages/404" */ '@/components/pages/NotFoundPage/'));

export default function AppAgent() {
  return (
    <AgentLayout>
      <Suspense fallback={<Spinner size="large" />}>
        <Switch>
          <Redirect from="/" to="/community/communities" exact />

          {/* 社区管理 */}
          <Route path="/community/communities" component={AgentCommunityListPage} exact />
          <Route path="/community/communities/:id(\d+)/inboxes" component={AgentInboxListPage} exact />
          <Route path="/community/communities/:id(\d+)/users" component={AgentUserListPage} exact />

          {/* 其它 */}
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Suspense>
    </AgentLayout>
  );
}
