import { useRecoilValue } from 'recoil';
import { useState, useEffect, useCallback } from 'react';

import { userState } from '@/stores/userStore';

export function useCollapse() {
  const [collapsed, setCollapsed] = useState(localStorage.ui_collapsed === '1');
  const toggle = useCallback(() => {
    let v = !collapsed;
    setCollapsed(v);
    localStorage.ui_collapsed = Number(v);
  }, [collapsed, setCollapsed]);

  return [collapsed, toggle];
}

export function useMenu() {
  const [menu, setMenu] = useState('device');

  return [menu, setMenu];
}

let menuData = null;

export function useMenuData() {
  const user = useRecoilValue(userState);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (user) {
      if (!menuData) {
        import('@/data/agent-menus.json').then((res) => res.default).then((json) => {
          setData(json);
          menuData = json;
        });
      } else {
        setData(menuData);
      }
    }
  }, [user]);

  return data;
}
