import cx from 'classnames';
import { trim } from 'lodash-es';
import { useRecoilValue } from 'recoil';
import { useLayoutEffect } from 'react';
import { Layout, Menu, Dropdown } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';

import styles from './style.less';
import useStyles from '@/hooks/useStyles';
import { userState } from '@/stores/userStore';
import { useFetchInfo, useLogout } from '@/hooks/useUser';
import { useCollapse, useMenu, useMenuData } from './hooks';
import AntIcon from '@/components/widgets/AntIcon/';

const defaultSelectedKeys = trim(location.pathname, '/') || 'community/communities';

function Sider({ menu, menus }) {
  const { pathname } = useLocation();
  const path = trim(pathname, '/').split('/').slice(0, 2).join('/');
  const [collapsed, setCollapsed] = useCollapse();
  const rows = menus.find((v) => v.key === menu) || [];

  return (
    <Layout.Sider
      className={styles.locals.sider}
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
    >
      <div className={styles.locals.logo}>
        <img
          className={styles.locals.logoImg}
          src={require('@/assets/images/logo.png')}
        />
        <span className={styles.locals.logoText}>新盒科技</span>
      </div>
      <Menu
        theme="dark"
        mode="inline"
        defaultOpenKeys={[defaultSelectedKeys.split('/').shift()]}
        defaultSelectedKeys={[defaultSelectedKeys]}
        selectedKeys={[path]}
      >
        {(rows.list || []).map((row) => <Menu.SubMenu
          key={row.key}
          title={
            <span>
              <AntIcon name={row.icon} />
              <span>{row.name}</span>
            </span>
          }
        >
          {(row.children || []).map((child) => <Menu.Item key={`${row.key}/${child.key}`}>
            <Link to={`/${row.key}/${child.key}`}>
              <AntIcon name={child.icon} />
              <span>{child.name}</span>
            </Link>
          </Menu.Item>)}
        </Menu.SubMenu>)}
      </Menu>
    </Layout.Sider>
  );
}

function Header({ user }) {
  const [/* loading */, onLogout] = useLogout();

  return (
    <Layout.Header className={styles.locals.header}>
      <div className={styles.locals.headerLeft}>
        &nbsp;
      </div>
      <div className={styles.locals.headerRight}>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="logout">
                <a
                  onClick={onLogout}
                >
                  退出登录
                </a>
              </Menu.Item>
            </Menu>
          }
        >
          <a
            className={styles.locals.headerUserItem}
            onClick={e => e.preventDefault()}
          >
            {user.Info.Username}
          </a>
        </Dropdown>
      </div>
    </Layout.Header>
  );
}

export default function AgentLayout({ className, children, ...nextProps }) {
  useStyles(styles);
  useFetchInfo();

  const menus = useMenuData();
  const history = useHistory();
  const [menu, setMenu]  = useMenu();

  useLayoutEffect(() => {
    const current = trim(location.pathname, '/').split('/').slice(0, 2).join('/');
    menus.forEach((a) => {
      if (a.list) {
        a.list.forEach((b) => {
          if (b.children) {
            b.children.forEach((c) => {
              if (current === [b.key, c.key].join('/')) {
                setMenu(a.key)
              }
            });
          }
        });
      }
    });
  }, [menus, history.location.pathname]);

  const user = useRecoilValue(userState);
  if (!user) {
    if (user === false) {
      history.replace('/login');
    }

    return null;
  }

  return (
    <Layout
      {...nextProps}
      className={cx(styles.locals.dashboard, className)}
    >
      <Sider menu={menu} menus={menus} />
      <Layout className={styles.locals.container}>
        <Header user={user} />
        <Layout.Content className={styles.locals.content}>
          {children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
}
